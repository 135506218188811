<template>
  <v-container fluid class="px-0">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs mt-3">
        <v-btn
          v-for="tab in tabs"
          :key="tab.id"
          :text="tab.id !== activeTab"
          :color="tab.id === activeTab ? 'primary' : ''"
          :outlined="tab.id === activeTab"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.id === activeTab ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ tab.name }}
        </v-btn>
      </v-col>
    </v-row>
    <router-view />
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "apiPaloAlto",
      tabs: [
        {
          id: "apiPaloAlto",
          name: "Palo Alto",
          route: "/settings/show/api/palo_alto",
        },
        {
          id: "apiFortinet",
          name: "Fortinet",
          route: "/settings/show/api/fortinet",
        },
        {
          id: "apiCheckpoint",
          name: "Checkpoint",
          route: "/settings/show/api/checkpoint",
        },
        {
          id: "apiSophos",
          name: "Sophos",
          route: "/settings/show/api/sophos",
        },
        {
          id: "apiCustom",
          name: "Custom",
          route: "/settings/show/api/custom",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
